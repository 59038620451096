export async function useGetArticleDetailByPermalink(
  permalink: string,
  folderId: number,
) {
  // NOTE: 「error」という変数名のまま使ってしまうとNuxt Test Utilsでエラーになるので as fetchError してる
  // ref: https://github.com/nuxt/test-utils/issues/835
  const { data, error: fetchError } = await useAsyncData(
    `get-article-detail-by-${permalink}`,
    () =>
      $fetch(`/api/kiwi_user/articles/detail`, {
        query: {
          folder_id: folderId,
          permalink,
        },
      }),
  );

  if (fetchError.value) {
    showError(fetchError.value);
  }

  return {
    data,
  };
}
